table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 14px;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  width: 1024px;
}
table thead tr {
  background-color: #01180b;
  color: #ffffff;
  text-align: left;
}
table th,
table td {
  padding: 10px 15px;
}
table tbody tr {
  border-bottom: 1px solid #dddddd;
  text-align: left;
}
table tbody tr:nth-of-type(even) {
  background-color: #f6f6f6;
}
table tbody tr:last-of-type {
  border-bottom: 2px solid #01180b;
}
table tbody tr.active-row {
  font-weight: bold;
  color: #4bbd7f;
}
table td:nth-child(2) input {
  width: 120px;
}
table .edit-cell-container button {
  border-radius: 50px;
  height: 26px;
  width: 26px;
  border: 1px solid #ccc;
  color: #ffb918;
}
table button[name="cancel"] {
  color: #7b7b7b;
}
table button[name="done"] {
  color: #4bbd7f;
}
table input,
select {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
}
table .edit-cell {
  display: flex;
  gap: 5px;
}

table .footer-buttons button {
  border: none;
  background-color: transparent;
}
table .add-button {
  color: #01180b;
}

table .edit-cell-action button[name="edit"] {
  color: #ffb918;
}
table .edit-cell-action button[name="cancel"] {
  color: #7b7b7b;
}
table .edit-cell-action button[name="done"] {
  color: #4bbd7f;
}
table .edit-cell-action button[name="remove"] {
  color: red;
  background-color: rgb(230, 208, 208);
}
table .edit-cell-action {
  display: flex;
  gap: 5px;
}

table .edit-cell-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
}
table input[type="checkbox"] {
  width: 16px;
  height: 16px;
}
table input[type="date"] {
  width: 100px;
}
table .remove-button {
  color: #e44747;
}
input:invalid {
  border: 2px solid red;
}
select:invalid,
input:invalid {
  border: 2px solid red;
}

table .edit-cell-action button[name="done"]:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* set for mobile breakpoint */
@media not print {
  @media (max-width: 980px) {
    /* display th for desktop only, hide on mobile */
    .desktop {
      display: none;
    }

    /* arranges td as column in the tr */
    .mobile-flex {
      display: flex;
      width: 100%;
    }

    /* adds faux-headers on each td by reading "data-header" attribute of the td*/
    td:before {
      content: attr(headers);
      display: block;
      font-weight: bold;
      margin-right: 10px;
      max-width: 110px;
      min-width: 110px;
      word-break: break-word;
    }
  }
  .print-only,
  .print-only * {
    display: none !important;
  }
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .print-only {
    display: block !important;
  }
}
